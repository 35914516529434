import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import { sendEmail } from "../../api";

import styles from "../../styles/info-site/ContactPageNew.module.css";

const ContactPage = ({children}) => {
    const [isFormLoaded, setIsFormLoaded] = useState(false);
    const [success, setSuccess] = useState(false);
    const [values, setValues] = useState({
      email: '',
      message: '',
    })

    const handleSubmit = e => {
      e.preventDefault();
      e.stopPropagation();

      sendEmail({
        username: "sales@tyviso.com",
        title: "Contact Message",
        content: `New Contact Message: <br /> <strong>Email:</strong> ${values.email} <br /> <strong>Message:</strong> ${values.message}`
      }).then(res => {
        if(res) {
          setSuccess(true)
        }
      })
    }

    const handleChange = e => {
      setValues({ ...values, [e.target.name]: e.target.value })
    }

    return (
        <div className={styles.Contact}>
          {
            !success
              ? (
                <>
                  <div className={styles.Conainer}>
                    <h2><FormattedMessage id="contactUs" /></h2>
                    <p><FormattedMessage id="contactUsDescription" /></p>
                    

                    <form onSubmit={handleSubmit}>
                      <div className={styles.FormRow}>
                        <label htmlFor="email">Your email</label>
                        <input required id='email' name='email' type='email' value={values.email} onChange={handleChange} />
                      </div>

                      <div className={styles.FormRow}>
                        <label htmlFor="message">Your message</label>
                        <textarea rows={3} required id='message' name='message' type='message' value={values.message} onChange={handleChange}  />
                      </div> 

                      <input className={styles.FormSubmit} type="submit" value="Contact Support" />
                    </form>
                  </div>
                </>
              ) : (
                <div className={styles.ConainerSuccess}>
                  <h4><FormattedMessage id="gwpQuoteThankYouTitle" /></h4>
                  <p><FormattedMessage id="gwpQuoteThankYouDesc" /></p>
                </div>
              )
          }
        </div>
    );
};

export default ContactPage;